import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Logo from './../assets/img/logo.png';
import { Link as ScrollLink, Events, scrollSpy } from 'react-scroll';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('page-top');

  const handleSetActive = (to) => {
    setActiveLink(to);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    Events.scrollEvent.register('begin', () => {
      // Do something at the beginning of scroll event if necessary
    });

    Events.scrollEvent.register('end', () => {
      // Do something at the end of scroll event if necessary
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  return (
    <>
      <motion.nav 
        className="fixed top-0 z-50 flex items-center justify-between w-full p-4 bg-white shadow" 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ fontFamily: 'Lato, sans-serif', fontSize: '15px', textTransform:'uppercase' }} // Set link size to 15px
      >
        <ScrollLink 
          to="page-top" 
          smooth={true} 
          duration={500} 
          className="cursor-pointer navbar-brand"
          onSetActive={() => handleSetActive('page-top')}
        >
          <img src={Logo} alt="Logo" className="h-16" /> {/* Increase logo size */}
        </ScrollLink>
        <button 
          type="button" 
          className={`relative w-8 h-8 lg:hidden`}
          onClick={handleToggle}
        >
          <span className="sr-only">Toggle navigation</span>
          <span className={`block absolute left-1/2 w-6 h-0.5 bg-gray-600 transition-transform duration-300 ${isOpen ? 'rotate-45' : '-translate-y-1.5'}`}></span>
          <span className={`block absolute left-1/2 w-6 h-0.5 bg-gray-600 transition-opacity duration-300 ${isOpen ? 'opacity-0' : 'opacity-100'}`}></span>
          <span className={`block absolute left-1/2 w-6 h-0.5 bg-gray-600 transition-transform duration-300 ${isOpen ? '-rotate-45' : 'translate-y-1.5'}`}></span>
        </button>
        <div className="items-center hidden lg:flex text-[#555]"> {/* Change text color for desktop */}
          <ul className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-6"> {/* Increase space between items */}
            {[
              { lien: 'activity', title: 'Nos activités' },
              { lien: 'about', title: 'À propos de nous' },
              { lien: 'objective', title: 'Nos objectifs' },
              { lien: 'galerie', title: 'Galerie' },
              { lien: 'supportus', title: 'Nos causes' },
              { lien: 'testimonials', title: 'Témoignages' },
              { lien: 'contact', title: 'Contact' }
            ].map((link, index) => (
              <li key={index}>
                <ScrollLink 
                  to={link.lien} 
                  smooth={true} 
                  duration={500} 
                  offset={-70} // Adjust offset as necessary
                  className={`cursor-pointer page-scroll relative ${activeLink === link.lien ? 'text-[#ca111f]' : ''}`}
                  onSetActive={() => handleSetActive(link.lien)}
                  spy={true}
                >
                  {link.title}
                  {activeLink === link.lien && (
                    <motion.div 
                      className="absolute bottom-0 left-0 right-0 h-1 bg-[#ca111f]"
                      layoutId="underline"
                    />
                  )}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </div>
      </motion.nav>

      {isOpen && (
        <motion.div 
          className="fixed left-0 right-0 z-50 p-4 text-white bg-gray-800 shadow-lg top-24 lg:hidden" // Adjusted top position
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ fontFamily: 'Lato, sans-serif', fontSize: '15px', textTransform:'uppercase' }} // Set link size to 15px
        >
          <ul className="flex flex-col items-center space-y-4"> {/* Increase space between items */}
            {[
              { lien: 'activity', title: 'Nos activités' },
              { lien: 'about', title: 'À propos de nous' },
              { lien: 'objective', title: 'Nos objectifs' },
              { lien: 'galerie', title: 'Galerie' },
              { lien: 'supportus', title: 'Nos causes' },
              { lien: 'testimonials', title: 'Témoignages' },
              { lien: 'contact', title: 'Contact' }
            ].map((link, index) => (
              <li key={index}>
                <ScrollLink 
                  to={link.lien} 
                  smooth={true} 
                  duration={500} 
                  offset={-70} // Adjust offset as necessary
                  className={`cursor-pointer page-scroll relative ${activeLink === link.lien ? 'text-[#ca111f]' : ''}`}
                  onSetActive={() => handleSetActive(link.lien)}
                  spy={true}
                  onClick={() => setIsOpen(false)}
                >
                  {link.title}
                  {activeLink === link.lien && (
                    <motion.div 
                      className="absolute bottom-0 left-0 right-0 h-1 bg-[#ca111f]"
                      layoutId="underline"
                    />
                  )}
                </ScrollLink>
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </>
  );
};

export default Navbar;
