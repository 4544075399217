import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Pic1 from './../assets/img/supportUs/01.jpg';
import Pic2 from './../assets/img/supportUs/02.jpg';
import Pic3 from './../assets/img/supportUs/03.jpg';
import Pic4 from './../assets/img/supportUs/04.jpg';
import Pic5 from './../assets/img/supportUs/05.jpg';
import Pic6 from './../assets/img/supportUs/06.jpg';
import Modal from './Modal';

const SupportUs = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCause, setSelectedCause] = useState('');

  const handleDonateClick = (causeTitle) => {
    setSelectedCause(causeTitle);
    setShowModal(true);
  };

  const causes = [
    {
      image: Pic3,
      title: 'Lutte contre la violence domestique',
      description: "Protéger et soutenir les femmes victimes de violences, en ligne avec l'appui des engagements du gouvernement ivoirien.",
    },
    {
      image: Pic5,
      title: 'Soutien aux sans-abri ',
      description: " Offrir un refuge et une assistance aux femmes sans domicile, selon directives de l'UA pour la protection des populations vulnérables.",
    },
    {
      image: Pic6,
      title: 'Aide aux enfants abandonnés',
      description: " Prendre en charge et réhabiliter les enfants laissés à eux-mêmes."
    },
    {
      image: Pic2,
      title: 'Éducation et formation',
      description: "Promouvoir l'accès à l'éducation et à la formation professionnelle pour tous, conformément aux Objectifs de Développement Durable.",
    },
  ];

  return (
    <div id="supportUs" className="py-20 text-center">
      <div className="container mx-auto">
        <motion.div 
          className="mb-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl font-bold text-[#ca111f]">Soutenez nos causes</h2>
          <div className="w-20 h-1 bg-[#ca111f] mx-auto mt-2 mb-6"></div>
          <p className="text-lg">Chez HWA, nous nous mobilisons pour plusieurs causes essentielles</p>
        </motion.div>
        <div className="grid grid-cols-1 gap-8 px-4 sm:grid-cols-2 lg:grid-cols-4">
          {causes.map((cause, index) => (
            <motion.div 
              className="cause-item" 
              key={index}
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <div className="flex flex-col justify-between h-full overflow-hidden bg-white rounded-lg shadow-lg">
                <img src={cause.image} alt={cause.title} className="object-cover w-full h-48" />
                <div className="flex flex-col justify-between flex-1 p-4">
                  <div>
                    <h4 className="mb-2 text-xl font-semibold">{cause.title}</h4>
                    <p className="mb-4">{cause.description}</p>
                  </div>
                  <button
                    onClick={() => handleDonateClick(cause.title)}
                    className="px-4 py-2 mt-4 text-white bg-[#ca111f] rounded-full hover:bg-[#b10e19] transition-colors duration-300"
                  >
                    Faire un don
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <Modal showModal={showModal} setShowModal={setShowModal} causeTitle={selectedCause} />
      </div>
    </div>
  );
};

export default SupportUs;
