import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Pic from './../assets/img/about.jpg';

const About = () => {
  return (
    <div id="about" className="py-20 bg-gray-50">
      <div className="container flex flex-wrap px-4 mx-auto">
        <motion.div 
          className="w-full p-4 md:w-1/2"
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <img src={Pic} className="w-full h-auto rounded-lg shadow-lg" alt="About Us" />
        </motion.div>
        <motion.div 
          className="w-full p-4 md:w-1/2"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="about-text">
            <h2 className="text-4xl font-bold text-[#ca111f]">A propos de nous</h2>
            <div className="w-20 h-1 bg-[#ca111f] my-4"></div>
            <p className="mt-4 text-lg">HWA (Humanitarian Women's Association) est une organisation humanitaire basée en Côte
d'Ivoire, dédiée à l'amélioration des conditions de vie des femmes battues, sans domicile et des
enfants abandonnés.
<br/>
Fondée sur des valeurs de compassion, de solidarité et d'engagement.
</p>
            <h3 className="mt-6 text-2xl font-semibold">Notre mission est de fournir</h3>
            <ul className="mt-4 space-y-4">
              <li className="flex items-center text-lg">
                <FontAwesomeIcon icon={faCheckCircle} className="text-[#ca111f] mr-2" />
                Un soutien crucial à ceux qui en ont le plus besoin.
              </li>
              <li className="flex items-center text-lg">
                <FontAwesomeIcon icon={faCheckCircle} className="text-[#ca111f] mr-2" />
                Plaider pour les droits et le bien-être des nécessiteux, en accord avec les conventions internationales des Nations Unies.
              </li>
              <li className="flex items-center text-lg">
                <FontAwesomeIcon icon={faCheckCircle} className="text-[#ca111f] mr-2" />
                 A chaque femme et chaque enfant mérite de vivre dans la dignité et la sécurité.
              </li>
            </ul>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
