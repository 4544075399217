import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faChartPie, faMagic, faHandsHelping, faPeopleCarry, faBullhorn } from '@fortawesome/free-solid-svg-icons';

const Objective = () => {
  const pulseAnimation = {
    scale: [1, 1.05, 1],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatType: "loop",
    },
  };

  return (
    <div id="objective" className="py-20 text-center text-white bg-gradient-to-r from-red-600 via-red-700 to-red-600">
      <div className="container px-4 mx-auto">
        <motion.div 
          className="mb-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl font-bold">Nos objectifs</h2>
          <div className="w-20 h-1 mx-auto mt-2 mb-6 bg-white"></div>
          <p className="text-lg">Nos objectifs sont clairs et axés sur l'amélioration durable des conditions de vie des
          populations les plus vulnérables</p>
        </motion.div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <motion.div 
            className="flex flex-col items-center justify-center p-6"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div 
              className="flex items-center justify-center w-24 h-24 mb-4 bg-white rounded-full shadow-lg"
              animate={pulseAnimation}
            >
              <FontAwesomeIcon icon={faHandsHelping} className="text-4xl text-[#ca111f]" />
            </motion.div>
            <div className="service-desc">
              <h3 className="text-xl font-semibold">Soutien immédiat et Microfinance participative </h3>
              <p>Fournir une aide d'urgence aux femmes et enfants en détresse. Mettre en place des programmes de microfinance pour
aider les femmes à lancer leurs propres petites entreprises, renforçant ainsi leur
indépendance économique.</p>
            </div>
          </motion.div>
          <motion.div 
            className="flex flex-col items-center justify-center p-6"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div 
              className="flex items-center justify-center w-24 h-24 mb-4 bg-white rounded-full shadow-lg"
              animate={pulseAnimation}
            >
              <FontAwesomeIcon icon={faPeopleCarry} className="text-4xl text-[#ca111f]" />
            </motion.div>
            <div className="service-desc">
              <h3 className="text-xl font-semibold">Empowerment</h3>
              <p> Promouvoir l'autonomie des femmes à travers la formation et
l'éducation, conformément aux ODD pour l'égalité des sexes et l'autonomisation des
femmes, et soutenu par les politiques ivoiriennes et les initiatives de l'UA pour la
promotion de l'autonomisation des femmes.</p>
            </div>
          </motion.div>
          <motion.div 
            className="flex flex-col items-center justify-center p-6"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div 
              className="flex items-center justify-center w-24 h-24 mb-4 bg-white rounded-full shadow-lg"
              animate={pulseAnimation}
            >
              <FontAwesomeIcon icon={faBullhorn} className="text-4xl text-[#ca111f]" />
            </motion.div>
            <div className="service-desc">
              <h3 className="text-xl font-semibold">Sensibilisation et Plaidoyer</h3>
              <p>Informer et sensibiliser la communauté sur les enjeux de la violence
              domestique et de l'abandon.  Défendre les droits des femmes et des enfants à travers des campagnes de
              plaidoyer et des partenariats stratégiques. </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Objective;
