import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import visaLogo from './../assets/img/payment/visa.svg';
import mastercardLogo from './../assets/img/payment/mastercard.svg';
import paypalLogo from './../assets/img/payment/paypal.svg';
import orangeMoneyLogo from './../assets/img/payment/orange_money.png';
import mtnMoneyLogo from './../assets/img/payment/mtn_money.png';
import moovMoneyLogo from './../assets/img/payment/moov_money.png';

const Modal = ({ showModal, setShowModal, causeTitle }) => {
  const [amount, setAmount] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
  };

  return (
    <>
      {showModal ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50" onClick={() => setShowModal(false)}>
          <motion.div 
            className="relative w-full max-w-md max-h-screen p-4 overflow-y-scroll bg-white rounded-lg shadow-lg" 
            onClick={(e) => e.stopPropagation()}
            initial={{ scale: 0.8, opacity: 0 }} 
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold">Faire un don</h2>
              <button className="text-black focus:outline-none" onClick={() => setShowModal(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <h3 className="mb-6 text-lg font-semibold text-center">{causeTitle}</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700">Montant</label>
                <input
                  type="number"
                  placeholder="Entrez le montant"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#ca111f]"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700">Nom</label>
                <input
                  type="text"
                  placeholder="Votre nom"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#ca111f]"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700">Email</label>
                <input
                  type="email"
                  placeholder="Votre email"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#ca111f]"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-bold text-gray-700">Mode de paiement</label>
                <select 
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#ca111f]" 
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option value="">Sélectionnez un mode de paiement</option>
                  <option value="orange_money">
                    <img src={orangeMoneyLogo} alt="Orange Money" className="inline w-6 mr-2" /> Orange Money
                  </option>
                  <option value="mtn_money">
                    <img src={mtnMoneyLogo} alt="MTN Money" className="inline w-6 mr-2" /> MTN Money
                  </option>
                  <option value="moov_money">
                    <img src={moovMoneyLogo} alt="Moov Money" className="inline w-6 mr-2" /> Moov Money
                  </option>
                  <option value="visa">
                    <img src={visaLogo} alt="Visa" className="inline w-6 mr-2" /> Visa
                  </option>
                  <option value="mastercard">
                    <img src={mastercardLogo} alt="Mastercard" className="inline w-6 mr-2" /> Mastercard
                  </option>
                  <option value="paypal">
                    <img src={paypalLogo} alt="Paypal" className="inline w-6 mr-2" /> Paypal
                  </option>
                </select>
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 mb-4 font-bold text-white bg-[#ca111f] rounded-lg hover:bg-[#b10e19] transition-colors duration-300"
              >
                Faire un don
              </button>
            </form>
            <div className="mt-4">
              <h4 className="mb-2 text-sm font-bold text-gray-700">Modes de paiement acceptés :</h4>
              <div className="flex justify-center space-x-4">
                <img src={visaLogo} alt="Visa" className="h-8" />
                <img src={mastercardLogo} alt="Mastercard" className="h-8" />
                <img src={paypalLogo} alt="Paypal" className="h-8" />
                <img src={orangeMoneyLogo} alt="Orange Money" className="h-8" />
                <img src={mtnMoneyLogo} alt="MTN Money" className="h-8" />
                <img src={moovMoneyLogo} alt="Moov Money" className="h-8" />
              </div>
            </div>
          </motion.div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
