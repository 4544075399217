import React from 'react';
import { motion } from 'framer-motion';
import Pic1 from './../assets/img/galerie/01.jpg';
import Pic2 from './../assets/img/galerie/02.jpg';
import Pic3 from './../assets/img/galerie/03.jpg';

const Galerie = () => {
  return (
    <div id="galerie" className="py-20 text-center">
      <div className="container mx-auto">
        <motion.div 
          className="mb-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl font-bold text-[#ca111f]">Galerie</h2>
          <div className="w-20 h-1 bg-[#ca111f] mx-auto mt-2 mb-6"></div>
          <p className="text-lg">Découvrez avec passion ce que nous faisons tout le temps</p>
        </motion.div>
        <div className="grid grid-cols-1 gap-8 px-4 sm:grid-cols-2 lg:grid-cols-3">
          {[Pic1, Pic2, Pic3, Pic1, Pic2, Pic3].map((item, index) => (
            <motion.div 
              className="portfolio-item" 
              key={index}
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <div className="relative overflow-hidden rounded-lg shadow-lg">
                <a href={item} title="Soutien aux femmes et enfants" data-lightbox-gallery="gallery1">
                  <img src={item} className="object-cover w-full h-64" alt="Soutien aux femmes et enfants" />
                  <div className="absolute inset-0 flex items-center justify-center text-white transition-opacity duration-300 bg-black bg-opacity-50 opacity-0 hover:opacity-100">
                    <h4 className="text-lg font-semibold">Soutien aux femmes et enfants</h4>
                  </div>
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Galerie;
