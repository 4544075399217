import React from 'react';
import { motion } from 'framer-motion';
import Pic from './../assets/img/01.jpg'

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      text: "Grâce à HWA, j'ai retrouvé ma dignité et l'espoir d'un avenir meilleur.",
      name: 'Marie, bénéficiaire.',
      image: Pic,
    },
    {
      id: 2,
      text: "Les ateliers de formation m'ont donné les compétences nécessaires pour subvenir aux besoins de ma famille.",
      name: 'Fatou, participante',
      image: Pic,
    },
    {
      id: 3,
      text: "Le programme de microfinance m'a permis de lancer mon petit commerce et de subvenir aux besoins de mes enfants.",
      name: 'Aïcha, bénéficiaire',
      image: Pic,
    },
  ];

  return (
    <div id="testimonials" className="py-20 text-center bg-gray-100">
      <div className="container px-4 mx-auto">
        <div className="mb-10 section-title">
          <h2 className="text-3xl font-bold">Avis</h2>
          <div className="w-20 h-1 mx-auto mt-2 mb-6 bg-[#ca111f]"></div>
        </div>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <motion.div 
              className="p-4 bg-white rounded-lg shadow-md testimonial" 
              key={index}
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <div className="mb-4 testimonial-image">
                <img src={testimonial.image} alt={testimonial.name} className="object-cover w-24 h-24 mx-auto rounded-full" />
              </div>
              <div className="testimonial-content">
                <p className="mb-4 text-gray-700">"{testimonial.text}"</p>
                <div className="font-bold text-gray-900">{testimonial.name}</div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
