import React from 'react';
import { motion } from 'framer-motion';

const Footer = () => {
  return (
    <motion.div 
      id="footer" 
      className="py-4 text-center text-gray-800 bg-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto">
        <p>© 2024 Tous droits réservés à HWA. Design by <a href="http://www.congo-digital.com" rel="nofollow" className="text-[#ca111f] hover:text-red-700 transition-colors duration-300">Congo Digital Services</a></p>
      </div>
    </motion.div>
  );
};

export default Footer;
