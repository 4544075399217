import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faBullhorn, faUsers, faMagic, faPiggyBank, faChalkboardTeacher, faHandHoldingHeart, faStethoscope } from '@fortawesome/free-solid-svg-icons';

const Activity = () => {
  return (
    <div id="activity" className="py-20 text-center">
      <div className="container px-4 mx-auto">
        <motion.div 
          className="mb-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-4xl font-bold text-[#ca111f]">Nos activités</h2>
          <div className="w-20 h-1 bg-[#ca111f] mx-auto mt-2 mb-6"></div>
        </motion.div>
        <div className="grid grid-cols-1 gap-8 mt-20 md:grid-cols-2 lg:grid-cols-4">
          {[
            {
              icon: faHandHoldingHeart,
              title: 'Assistance sociale et psychologique',
              description: "Nous aidons les victimes de violences à reconstruire leur vie avec un soutien psychosocial en accord avec les directives internationales et locales. ",
              delay: 0
            },
            {
              icon: faChalkboardTeacher,
              title: 'Formation et éducation',
              description: "Nous offrons des formations professionnelles et des programmes éducatifs pour promouvoir l'autonomie et l'indépendance.",
              delay: 0.1
            },
            {
              icon: faStethoscope,
              title: 'Santé et bien-être',
              description: "Nous assurons des soins de santé de base et un soutien nutritionnel pour le bien-être de nos bénéficiaires.",
              delay: 0.2
            },
            {
              icon: faPiggyBank,
              title: 'Microfinance participative',
              description: "Nous avons un programme de microfinance pour aider les femmes à créer des activités génératrices de revenus et sortir de la pauvreté.",
              delay: 0.3
            }
          ].map((activity, index) => (
            <motion.div 
              key={index}
              className="flex flex-col items-center justify-start p-6 bg-white rounded-lg shadow-md"
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: activity.delay }}
            >
              <div className="flex items-center justify-center w-24 h-24 mb-4 rounded-full shadow-lg bg-gradient-to-r from-red-600 to-red-800">
                <FontAwesomeIcon icon={activity.icon} className="text-4xl text-white" />
              </div>
              <h3 className="text-xl font-semibold">{activity.title}</h3>
              <p>{activity.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Activity;
