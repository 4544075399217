import React from 'react';
import { motion } from 'framer-motion';
import Fond from './../assets/img/intro-bg.jpg';

const Header = () => {
  return (
    <header id="header" className="relative w-full h-screen pt-20 bg-top bg-cover" style={{ backgroundImage: `url(${Fond})` }}>
      <div className="absolute inset-0 z-10 bg-black opacity-40"></div>
      <div className="container flex items-center justify-center h-full mx-auto">
        <motion.div 
          className="z-20 text-center text-white"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="mb-4 text-5xl font-bold leading-tight">Bienvenue sur la page de nos activités !</h2>
          <p className="mb-8 text-xl">À HWA, nous nous engageons activement à soutenir les
          femmes battues et sans domicile ainsi que les enfants abandonnés.</p>
         <a 
            href="#Modal.js" 
            className="inline-block px-8 py-3 text-lg font-semibold text-white transition-colors duration-300 transform bg-[#ca111f] rounded-full hover:bg-[#b10e19] hover:shadow-lg"
          >
            Faire un don
          </a> 
        </motion.div>
      </div>
    </header>
  );
};

export default Header;
