import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Activity from './components/Activity';
import About from './components/About';
import Objective from './components/Objective';
import Galerie from './components/Galerie';
import SupportUs from './components/SupportUs';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <div id="page-top"></div>
      <Navbar />
      <Header />
      <div id="activity">
        <Activity />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="objective">
        <Objective />
      </div>
      <div id="galerie">
        <Galerie />
      </div>
      <div id="supportus">
        <SupportUs />
      </div>
      <div id="testimonials">
        <Testimonials />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
