import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
  return (
    <div id="contact" className="py-20 text-white bg-gradient-to-r from-red-600 via-red-700 to-red-600">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap justify-center">
          <motion.div 
            className="w-full p-4 md:w-2/3"
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="mb-10 section-title">
              <h2 className="text-4xl font-bold">Contactez-nous</h2>
              <p className="mt-2">Nous sommes ouverts pour plus d'informations</p>
            </div>
            <form name="sentMessage" className="w-full">
              <div className="flex flex-wrap -mx-2">
                <div className="w-full p-2 md:w-1/2">
                  <div className="form-group">
                    <input type="text" id="name" name="name" className="w-full p-2 border rounded form-control focus:outline-none focus:ring-2 focus:ring-white" placeholder="Votre nom" required />
                  </div>
                </div>
                <div className="w-full p-2 md:w-1/2">
                  <div className="form-group">
                    <input type="email" id="email" name="email" className="w-full p-2 border rounded form-control focus:outline-none focus:ring-2 focus:ring-white" placeholder="Votre Email" required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea name="message" id="message" className="w-full p-2 border rounded form-control focus:outline-none focus:ring-2 focus:ring-white" rows="4" placeholder="Votre message" required></textarea>
              </div>
              <div className="flex justify-center">
                <button type="submit" className="px-8 py-2 mt-4 text-white transition-colors duration-300 border-2 border-white rounded-lg hover:bg-white hover:text-red-600">
                  Envoyez
                </button>
              </div>
            </form>
          </motion.div>
          <motion.div 
            className="w-full p-4 md:w-1/3"
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="contact-info">
              <div className="mb-4 contact-item">
                <h3 className="mb-2 text-2xl font-bold">Besoin de nous parler?</h3>
                <p className="flex items-center"><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /> Adresse: 10 rue Arena city</p>
              </div>
              <div className="mb-4 contact-item">
                <p className="flex items-center"><FontAwesomeIcon icon={faPhone} className="mr-2" /> Téléphone: +33 6 68 56 36 78</p>
              </div>
              <div className="contact-item">
                <p className="flex items-center"><FontAwesomeIcon icon={faEnvelope} className="mr-2" /> Email: info@company.com</p>
              </div>
              
            </div>
          </motion.div>
        </div>
      </div>
      <motion.div 
            className="w-full p-4"
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="mt-4 border-t border-gray-400 social">
                <ul className="flex justify-center mt-10 space-x-4">
                  <li>
                    <a href="https://www.facebook.com/hwafrica" className="flex items-center justify-center w-12 h-12 text-2xl text-white transition-colors duration-300 border-2 border-white rounded-full hover:bg-white hover:text-red-600">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com" className="flex items-center justify-center w-12 h-12 text-2xl text-white transition-colors duration-300 border-2 border-white rounded-full hover:bg-white hover:text-red-600">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com" className="flex items-center justify-center w-12 h-12 text-2xl text-white transition-colors duration-300 border-2 border-white rounded-full hover:bg-white hover:text-red-600">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                </ul>
              </div>
          </motion.div>
    </div>
  );
};

export default Contact;
